.main {
    background-color: var(--app-white);
    padding: 1.5rem 1rem;
    border-radius: 1rem;
    width: 15rem;
}

.nameContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.name {
    font-size: 1.125rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
}

.editContainer {
    text-align: center;
    padding-top: 1rem;
    border-top: 1px solid var(--border-light-grey);
    color: var(--app-primary);
    font-weight: 500;
}

.cardFieldName {
    color: var(--app-grey);
}

.podLink {
    user-select: text;
    font-size: 0.875rem;
}

.podLink>a {
    text-decoration: none;
    word-wrap: break-word;
}

.podLink>a:link,
.podLink>a:visited {
    color: var(--app-primary);
}

.copyLink,
.viewEvents {
    cursor: pointer;
}

.codeContainer {
    display: grid;
    grid-template-columns: auto auto;
    margin: 1.75rem 0;
}

.descriptionContainer {
    margin: 0.75rem 0;
    height: 3.75rem;
}

.linkContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.25rem;
}

.cardFieldValue {
    font-size: 1rem;
}

.cardFieldName,
.label {
    font-size: 0.75rem;
}