.main,
.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 22rem;
}

.actions {
    display: flex;
    margin-top: 1rem;
}

.actionsBtn {
    flex: 1;
}

.patientName {
    font-size: 1rem;
    font-weight: 600;
}

.age {
    font-size: 0.875rem;
    margin-top: 0.25rem;
    margin-bottom: 1.25rem;
}

.dataFieldText {
    margin: 0.5rem 0;
    font-size: 0.875rem;
}

.service {
    margin-top: 1rem;
    padding: 0.5rem 0;
    border-top: 1px solid var(--app-semi-light-grey);
    border-bottom: 1px solid var(--app-semi-light-grey);
}

.card {
    margin: 0.5rem 0;
}

.bloodPressure {
    display: flex;
    gap: 0.5rem;
}

.observations,
.symptoms,
.medications {
    font-size: 1rem;
    color: var(--app-grey);
}

.symptoms {
    margin-top: 1.875rem;
}

.symptomsDescription {
    font-size: 0.875rem;
    margin-top: 0.5rem;
}

.medicationsAdministered {
    margin-bottom: 1.875rem;
}

.cardImage {
    max-width: 16rem;
    max-height: 16rem;
    cursor: pointer;
}

.imageViewer {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: rgba(0, 0, 0, 0.5);
    border-radius: inherit;
}

.imageContainer {
    align-self: center;
}

.viewImage {
    max-width: 40rem;
    max-height: 40rem;
    object-fit: contain;
}

.closeIcon {
    position: absolute;
    margin-left: -2.625rem;
    margin-top: 0.5rem;
    cursor: pointer;
}

.administeredTime {
    margin-bottom: 0.5rem;
}

.attachments {
    margin: 0.875rem 0;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    color: var(--app-primary);
}

.attachments:hover {
    background-color: var(--app-light-purple);
    width: fit-content;
}

.attachments>img {
    width: 1.25rem;
    height: 1.625rem;
    object-fit: contain;
}