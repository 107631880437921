.main {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-height: 100%;
}

.tableContainer {
    flex: 1;
    max-height: 100%;
    overflow-y: auto;
}

.main table {
    width: 100%;
    border-spacing: 0;
    flex: 1;
}

.thead>tr {
    background-color: var(--app-white);
    position: sticky;
    top: 0;
    z-index: 1;
}

.main td,
.main th {
    padding: 1rem 0.25rem;
    border-bottom: 1px solid var(--border-light-grey);
    font-size: 0.875rem;
}

.main th {
    color: var(--app-grey);
    font-size: 0.875rem;
}

.row {
    font-weight: normal;
}

.row td:first-child {
    text-align: center;
}

.rowHovered {
    background-color: #ead0f6;
    transition: background-color 0.3s ease;
}

.main th {
    text-align: left;
    font-weight: normal;
}

.main th:first-child {
    text-align: center;
}