.main {
  font-size: 0.875rem;
  color: var(--app-primary);
  user-select: none;
  width: max-content;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.icon {
  font-size: 1.125rem;
  display: flex;
  align-items: center;
}

.main:not(:disabled) {
  cursor: pointer;
}

.disabled {
  pointer-events: none;
  opacity: 0.3;
  cursor: auto;
}
