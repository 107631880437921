.main {
    width: max-content;
    padding: 0.5rem 1.25rem;
    border: 1px solid var(--app-primary);
    border-radius: 3rem;
    font-size: 0.75rem;
    cursor: pointer;
}

.active {
    background-color: var(--app-primary);
    border: 1px solid var(--app-primary);
    color: var(--app-white);
}