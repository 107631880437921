.main {
    width: 30rem;
}

.actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1rem;
}

.input {
    padding-top: 1rem;
}