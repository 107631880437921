.main {
    width: 16rem;
    background-color: var(--app-white);
    border-radius: 1rem;
    padding: 1rem;
}

.topContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.icon {
    margin-right: 0.5rem;
}

.icon,
.viewEvents,
.edit {
    cursor: pointer;
}

.edit,
.icon {
    color: var(--app-grey);
}

.name {
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.125rem;
    margin-top: 0.5rem;
}

.date {
    font-size: 0.875rem;
    color: var(--app-grey);
    margin-top: 0.75rem;
}

.order {
    padding: 1.375rem 0;
    font-size: 1rem;
}

.medServiceContainer {
    border-top: 2px solid var(--border-light-grey);
    padding-top: 1rem;
}

.medServices {
    display: flex;
    flex-wrap: wrap;
    gap: 0.625rem;
    margin-top: 0.5rem;
}

.editContainer {
    display: flex;
    justify-content: space-between;
    padding-top: 0.5rem;
    margin-top: 0.5rem;
}