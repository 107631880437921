.main {
    width: 30rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1rem;
}

.textAreaHeight {
    min-height: 3.25rem;
}

.codeContainer {
    display: flex;
    column-gap: 0.5rem;
}