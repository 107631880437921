.main {
    min-width: 3.75rem;
    width: max-content;
    text-align: center;
    padding: 0.5rem 0.75rem;
    font-size: 0.75rem;
    border-radius: 3rem;
    background-color: #fafafa;
    border: 2px solid #cecece;
}

.active {
    background-color: #dcedd3;
    border-color: #b0e19a;
}

.upcoming {
    background-color: #e6e8d0;
    border-color: #cacd9c;
}

.closed {
    background-color: #facbcb;
    border-color: #fe9c9c;
}