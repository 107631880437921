.billingContainer {
    display: flex;
    justify-content: space-between;
    column-gap: 1rem;
}

.container {
    display: flex;
    width: 65%;
}

.status {
    display: flex;
    gap: 0.75rem;
    align-items: center;
    padding: 1.125rem;
    flex-wrap: wrap;
}

.nameContainer {
    padding-right: 1.875rem;
    border-right: 1px solid var(--border-light-grey);
    width: 18rem;
}

.name {
    font-size: 0.875rem;
    font-weight: 700;
    align-self: self-start;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.podAddress {
    font-size: 0.875rem;
    margin-top: 0.25rem;
}

.eventContainer {
    padding-left: 1rem;
    width: 100%;
}

.eventNameContainer {
    max-height: 12rem;
    min-height: 3rem;
    overflow-y: auto;
    padding-top: 0.25rem;
    font-size: 0.875rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    padding-right: 0.5rem;
}

.sendForBillingCheckbox label {
    font-size: 0.875rem;
}

.actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 0.75rem;
}

.action {
    width: 10rem;
}

.actionContainer {
    display: flex;
    justify-content: space-between;
}

.actionsDisabled {
    pointer-events: none;
    opacity: 0.5;
}

.eventsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    column-gap: 0.5rem;
}

.eventName {
    width: 100%;
    text-wrap: wrap;
}

.eventStatus {
    padding: 0.125rem 0.125rem !important;
}

@media screen and (max-width: 1160px) {
    .container {
        width: 64%;
    }

    .nameContainer {
        width: 14rem;
    }
}