.main {
    max-height: 100%;
    overflow-y: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    border-radius: 1.25rem;
    background-color: var(--app-white);
    padding: 0.75rem 1.875rem;
    margin-top: 1rem;
}