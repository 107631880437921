.card {
    flex: 1;
    max-height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.main {
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 1rem;
    align-content: flex-start;
}

.noEventsMain {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    height: 100%;
    justify-content: center;
}

.noEventsImage {
    width: 18rem;
    object-fit: contain;
}

.noEventsTitle {
    color: #A0A0A0;
    font-size: 1.5rem;
}
