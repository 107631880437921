.main {
    display: flex;
    height: 100%;
    width: 100%;
}

.left {
    flex: 1;
    display: flex;
    padding: 1.625rem 0 1.625rem 1.625rem;
}

.right {
    flex: 5;
    display: flex;
}