.main {
    display: flex;
    flex-direction: column;
    margin-right: 0.75rem;
}

.row {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 0.5rem;
}

.inputItem>label {
    font-size: 1rem;
}

.inputItem>select {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    font-size: 1.1rem;
    font-weight: 500;
    padding-left: 0.5rem;
    margin-top: 0.3rem;
}

.products {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-top: 2rem;
    border-radius: 3px;
    align-self: center;
}

.productTitle {
    height: 2rem;
    width: 25%;
    display: flex;
    font-size: 1rem;
}

.selectItemContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    gap: 1.5rem;
}

.heading {
    font-size: 0.8rem;
}

.actions {
    flex: 1;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.options {
    display: flex;
    flex-direction: column;
}

.input {
    padding-top: 1rem;
}

.reportCheckbox {
    margin-top: 2.25rem;
}