.main {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 22rem;
}

.card {
    margin: 0.5rem 0;
}

.eventName {
    font-weight: bold;
}

.address {
    font-size: 0.875rem;
    margin-top: 0.25rem;
    margin-bottom: 1.25rem;
}

.hr {
    border: 1px solid var(--app-semi-light-grey);
    margin-top: 0.875rem;
}

.services {
    display: flex;
}

.serviceNames {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    margin-left: 1rem;
}