.main {
    height: 3.5rem;
    width: 20rem;
}

.selectContainer {
    background-color: white;
}

.select {
    font-size: 0.875rem;
}