.backdrop {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
}

.main {
    height: 100%;
    overflow-y: auto;
    background-color: var(--app-semi-light-grey);
    z-index: 1;
    display: flex;
    flex-direction: column;
    animation: slideFromRight 0.4s ease-in-out;
}

.container {
    margin: 2rem 1.25rem;
}

.titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem
}

.title {
    font-size: 1.25rem;
}

.close {
    cursor: pointer;
    margin-left: 0.5rem;
}

@keyframes slideFromRight {

    0%,
    30% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}