.main {
    position: relative;
}

.options {
    position: absolute;
    z-index: 2;
    background-color: white;
    border: 1px solid var(--border-light-grey);
    padding: 0.625rem 0.5rem;
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    margin-top: 0.125rem;
}

.button {
    text-wrap: nowrap;
}

.option {
    min-width: 6rem;
    text-wrap: nowrap;
    margin-bottom: 0.125rem;
}

.arrowDown {
    filter: brightness(0) invert(1);
}