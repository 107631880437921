.basicDetails {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 0.75rem;
}

.actions {
    display: flex;
    padding-top: 1.5rem;
}

.actionBtn {
    width: 10rem;
}

.input {
    padding-top: 1rem;
}

.infoQuestions {
    padding: 0.5rem;
    padding-bottom: 2rem;
}

.options {
    padding-top: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
}

.question {
    font-weight: 500;
}

.option {
    display: flex;
    gap: 1.5rem;
}

.editAction {
    margin-right: 1rem;
}

.uploadImageContainer {
    display: flex;
    gap: 4rem;
}

.textarea {
    min-height: 2.25rem;
}

.uploadImg {
    pointer-events: none;
    width: 60%;
    max-height: 60%;
    z-index: 1;
    object-fit: contain;
}

.noSelected {
    background-color: var(--app-light-purple);
    padding: 0.75rem;
    padding-left: 1.5rem;
    font-size: 0.875rem;
    color: var(--app-grey);
}

.uploadLogoImg {
    pointer-events: none;
    width: 30%;
    max-height: 30%;
    margin: 2.875rem 0;
    object-fit: contain;
}

.logoImage {
    margin: 0rem 0;
}

.infoContainer {
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
}

.tableContainer {
    padding: 1rem 0;
}

.questionType {
    color: var(--app-grey);
    font-style: italic;
}

.helpContainer {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 0.625rem;
    padding-right: 2rem;
}

.helpBtn>span {
    border-bottom: 1px solid var(--app-primary);
}

.landingPageContainer {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 0.5rem;
}