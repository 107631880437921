.main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 7.5rem;
    width: 13.5rem;
    padding: 1rem;
    cursor: pointer;
    background-color: white;
    margin: 0.5rem;
    border-radius: 1.25rem;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.main:hover {
    background-color: var(--app-primary2);
    color: var(--app-white);
}

.icon {
    width: 2.5rem;
}

.main span {
    font-weight: 500;
}