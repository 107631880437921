.main {
    width: 60vw;
    height: 60vh;
    display: flex;
    flex-direction: column;
}

.SelectContainer {
    width: 50%;
    min-height: 1.5rem;
    display: flex;
}

.tableContainer {
    flex: 1;
    display: flex;
    margin-top: 2rem;
}

.footer {
    display: flex;
    justify-content: center;
    width: 100%;
}