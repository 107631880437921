.checkbox {
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.checkbox>input {
    margin: 0;
}

/* Make the underlying checkbox hidden */
input[type=checkbox] {
    position: relative;
    width: 1.25rem;
    height: 1.25rem;
}

input[type=checkbox]:before {
    content: "";
    display: block;
    position: absolute;
    width: 1.25rem;
    height: 1.25rem;
    top: 0;
    left: 0;
    border: 1px solid var(--border-light-grey);
    border-radius: 3px;
    background-color: var(--app-white);
}

input[type=checkbox]:checked:after {
    content: "";
    display: block;
    width: 0.375rem;
    height: 0.625rem;
    border: solid var(--app-primary);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 0.2rem;
    left: 0.4rem;
}

.checkbox>label {
    cursor: pointer;
    margin-left: 1rem;
}