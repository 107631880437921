.main {
    width: 25rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1rem;
}

.hasSerialNumbersCheckbox {
    font-size: 0.875rem;
}