.editAction {
    margin-right: 1rem;
}

.questionType {
    color: var(--app-grey);
    font-style: italic;
}

.statusSelect {
    flex-direction: row;
    column-gap: 0.5rem;
    height: 2.125rem;
}

.selectContainer {
    background-color: white;
    min-width: 5rem;
}

.left {
    display: flex;
    gap: 2rem;
}