.main {
    height: 65vh;
    width: 75vw;
    display: flex;
    flex-direction: column;
}

.actions {
    display: flex;
    justify-content: center;
    width: 100%;
}

.actionBtn {
    margin-left: 1rem;
}

.information {
    font-style: italic;
    font-size: 0.875rem;
    padding-top: 1rem;
}