.filterContainer {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    align-items: flex-start;
}

.tableContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 100%;
    overflow-y: auto;
}

.filters {
    gap: 1.5rem;
    display: flex;
    flex-wrap: wrap;
}

.actionBtn {
    display: flex;
    gap: 1rem;
    margin-top: 0.875rem;
}

.select,
.input {
    width: 13.5rem;
}

.selectFilter {
    min-width: 13.5rem;
}

.data {
    width: 12rem;
    font-size: 0.875rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}