.main {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 0.875rem;
    padding: 0.75rem;
    margin: 0.125rem 0;
    color: var(--app-primary);

}

.iconContainer {
    width: 1.125rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.active {
    background: var(--app-gradient);
    color: var(--app-white);
    border-radius: 0.5rem;
    animation: activeAnimation 0.3s;
}

@keyframes activeAnimation {
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}