.main {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-height: 100%;
}

.tableContainer {
    flex: 1;
    max-height: 100%;
    overflow-y: auto;
}

.main table {
    width: 100%;
    border-spacing: 0;
    flex: 1;
}

.thead>tr {
    background-color: var(--app-white);
    position: sticky;
    top: 0;
    z-index: 1;
}

.main td,
.main th {
    padding: 1rem 0.25rem;
    border-bottom: 1px solid var(--border-light-grey);
    font-size: 0.875rem;
}

.main th {
    color: var(--app-grey);
    font-size: 0.875rem;
}

.row {
    font-weight: normal;
}

.row td:first-child {
    text-align: center;
}

.main th {
    text-align: left;
    font-weight: normal;
}

.main th:first-child {
    text-align: center;
}

.paginationContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    font-weight: 500;
}

.actionBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.75rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.pageNo {
    margin: 0 0.75rem;
    font-size: 0.875rem;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}