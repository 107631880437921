.addBtn {
  display: flex;
  justify-content: flex-end;
}

.row {
  display: flex;
}

.main {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.filter {
  display: flex;
}

.actions {
  display: flex;
  padding-top: 2.5rem;
}

.actionBtn {
  width: 10rem;
}

.otherContainer,
.basicDetails,
.addressDetails {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0.5rem;
}

.tableContainer {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
}

.noSelected {
  background-color: var(--app-light-purple);
  padding: 0.75rem;
  padding-left: 1.5rem;
  font-size: 0.875rem;
  color: var(--app-grey);
  margin: 1rem 0;
}

.input {
  margin-top: 1rem;
}

.medProfListContainer {
  padding: 1rem 0;
}

.homeEventCheckbox {
  align-self: last baseline;
  padding-bottom: 0.375rem;
  padding-left: 0.125rem;
  font-size: 0.875rem;
  padding-top: 1rem;
}

.eventServiceContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.helpContainer {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 0.625rem;
  padding-right: 2rem;
}

.helpBtn>span {
  border-bottom: 1px solid var(--app-primary);
}