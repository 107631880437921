.main {
    position: relative;
}

.copyIcon {
    cursor: pointer;
    width: 1rem;
}

.copiedPopup {
    display: inline;
    position: absolute;
}

.copiedPopup span {
    font-size: 0.875rem;
    white-space: nowrap;
    border-radius: 0.5rem;
    background-color: dimgray;
    color: white;
    padding: 0.5rem;
    margin-left: 0.5rem;
}