.main {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100%;
  overflow-y: auto;
}

.filterContainer {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.filters {
  gap: 1.5rem;
  display: flex;
  flex-wrap: wrap;
}

.select {
  width: 25rem;
}

.filterDates {
  width: min-content;
}

.searchBar {
  align-self: flex-end;
}

.filterBtnContainer {
  display: flex;
  align-self: flex-end;
  gap: 1rem;
}

.filterBtn {
  align-self: flex-end;
}

.eventServiceContainer {
  display: flex;
  align-items: center;
}
