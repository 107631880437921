.main {
    display: flex;
    flex-direction: row;
    padding-bottom: 0.75rem;
    justify-content: space-between;
}

.left {
    display: flex;
}

.right {
    display: flex;
}