.App {
    display: flex;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    background-color: var(--app-semi-light-grey);
}

* {
    user-select: none;
}

input,
select {
    user-select: auto;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #101010;
}

:root {
    --app-primary: #61277F;
    --app-primary-dark: #5B2981;
    --app-primary2: #483189;
    --app-warning: #FF3632;
    --app-success: #00DCA6;
    --app-white: #FFF;
    --app-black: #000;
    --app-grey: #5A5A5A;
    --app-semi-light-grey: #EFEDED;
    --app-light-purple: #fbf2ff;
    --border-light-grey: #CCC;
    --app-gradient: transparent linear-gradient(98deg, #C65598 0%, #5B2981 68%, #483189 100%);
}

/* Hiding arrows of input number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

input::-webkit-calendar-picker-indicator {
    font-size: 1.4rem;
}

/* Scrollbar styling */
::-webkit-scrollbar {
    width: 0.625rem;
}

::-webkit-scrollbar-track {
    background: rgb(201, 201, 201);
    border-radius: 0.625rem;
}

::-webkit-scrollbar-thumb {
    background: rgb(160, 160, 160);
    border-radius: 0.625rem;
}

::-webkit-scrollbar-thumb:hover {
    background: rgb(140, 140, 140);
}

::-webkit-scrollbar-thumb:active {
    background: rgb(120, 120, 120);
}