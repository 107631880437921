.loader {
    border: 0.25rem solid #f3f3f3;
    border-radius: 50%;
    border-top: 0.25rem solid var(--app-primary);
    width: 3rem;
    height: 3rem;
    -webkit-animation: spin 1s linear infinite;
    /* Safari */
    animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.container {
    position: fixed;
    /* background-color: rgb(0, 0, 0, 0.5); */
    z-index: 1000;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}