.main {
    padding: 2.5rem;
    max-width: 20rem;
}

.title {
    text-align: left;
    font-weight: 500;
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
}

.messageContainer {
    width: 100%;
    text-align: left;
    color: gray;
}

.actionContainer {
    border-top: 1px solid lightgray;
    margin-top: 1.25rem;
    padding-top: 1rem;
    display: flex;
    justify-content: center;
}