.main {
    height: 66vh;
    width: 50vw;
    display: flex;
    flex-direction: column;
}

.header {
    width: 100%;
}

.searchDiv {
    display: flex;
    align-items: center;
}

.searchDiv>button {
    margin-left: 1rem;
}

.container {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.selectionBox {
    height: 2rem;
    min-height: 2rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.selectionBox>span {
    margin-left: 1rem;
}

.actions {
    display: flex;
    justify-content: center;
    width: 100%;
}

.addBtn {
    margin-right: 1rem;
}

.modal {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}