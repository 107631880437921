.main {
    width: 16rem;
    background-color: var(--app-white);
    border-radius: 1rem;
    padding: 1rem;
}

.topContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.name {
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.125rem;
    margin-top: 0.875rem;
}

.date {
    font-size: 0.875rem;
    color: var(--app-grey);
    margin-top: 1rem;
}