.main {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-top: 1rem;
  padding-right: 1rem;
}

.filterCard {
  margin-top: 1rem;
}

.filterContainer {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.filters {
  flex: 1;
  gap: 1.5rem;
  display: flex;
  flex-wrap: wrap;
}

.searchContainer {
  align-self: flex-end;
}

.filterBtn {
  align-self: flex-end;
}

.filterDates {
  max-width: 12rem;
}

.actionsContainer {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 2rem;
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.5rem;
}

.action {
  width: 10rem;
}

.sendForBillingCheckbox label {
  font-size: 0.875rem;
}

.innerContainer {
  margin-top: 0.5rem;
}

.actionsDisabled {
  pointer-events: none;
  opacity: 0.5;
}

.helpContainer {
  align-self: flex-end;
  padding-right: 0.25rem;
}

.helpBtn>span {
  border-bottom: 1px solid var(--app-primary);
}