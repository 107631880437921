.main,
.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow-y: auto;
}

.selectionBox {
    height: 2rem;
    min-height: 2rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.selectionBox>span {
    margin-left: 1rem;
}

.checkboxContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}