.main {
    width: 24rem;
    display: flex;
    flex-direction: column;
}

.input {
    margin-top: 1rem;
    width: 100%;
}

.actions {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
}

.input::file-selector-button {
    width: 10rem;
    height: 2.25rem;
    color: var(--app-primary);
    background-color: transparent;
    border: 1px solid var(--app-primary);
    border-radius: 0.25rem;
    font-size: 0.875rem;
    margin-right: 1rem;
    cursor: pointer;
}