/* Grid */
.row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

/* Calendar */
.calendar {
    display: block;
    position: relative;
    width: 100%;
}

.header {
    font-size: 1.25rem;
    padding: 1rem 0;
}

.dayOfWeek {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-basis: calc((100% - 0.25rem) / 7);
    width: calc((100% - 0.25rem) / 7);
    min-width: 5rem;
}

.dayItems {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.cell {
    position: relative;
    overflow: hidden;
    padding: 0.25rem;
    box-sizing: border-box;
}

.dayHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 1.25rem 0.75rem;
    margin: 0.125rem;
    background-color: var(--app-semi-light-grey);
    border-radius: 0.75rem;
}

.dayHeader.today {
    color: var(--app-white);
    background-color: var(--app-primary2);
}

.day {
    font-weight: 600;
    font-size: 0.875rem;
}

.date {
    font-weight: 600;
    font-size: 1.5rem;
    margin-top: 0.5rem;
}

.week {
    font-weight: 600;
}

.arrowIcon {
    margin-left: 1rem;
    cursor: pointer;
}

.disabledIcon {
    opacity: 0.5;
    cursor: auto;
}

@media screen and (max-width: 1280px) {

    .dayOfWeek {
        flex-basis: 100%;
        flex-direction: row;
    }

    .dayHeader {
        flex: 0.25;
    }

    .dayItems {
        flex: 0.75;
        width: 50%;
    }
}