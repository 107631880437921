.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
}

.title {
    font-size: 1.75rem;
    font-weight: 700;
}

.icon {
    margin-right: 0.5rem;
    height: 1.1rem;
    width: 1.1rem;
}

.cursor {
    cursor: pointer;
}

.right {
    color: var(--app-black);
    background-color: var(--app-white);
    padding: 0.8rem 2.6rem 0.8rem 2.6rem;
    font-weight: 500;
    font-size: 0.875rem;
    border-radius: 1.5rem;
}

.dropDownIcon {
    width: 1rem;
}

.email {
    display: flex;
    gap: 0.5rem;
}