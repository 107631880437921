.boxContainer {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 0.5rem;
}

.actions {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    margin-top: 2rem;
}

.input {
    padding-top: 1rem;
}

.rolesContainer,
.servicesContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    margin-top: 0.5rem;
}

.label {
    padding-top: 1.5rem;
}

.helpContainer {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 0.625rem;
    padding-right: 2rem;
}

.helpBtn>span {
    border-bottom: 1px solid var(--app-primary);
}