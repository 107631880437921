.filterContainer {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    align-items: flex-start;
}

.main {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 100%;
    overflow-y: auto;
}

.filters {
    gap: 1.5rem;
    display: flex;
    flex-wrap: wrap;
}

.searchBar {
    align-self: flex-end;
}

.actionBtns {
    display: flex;
    gap: 1rem;
    margin-top: 0.875rem;
}

.select {
    width: 25rem;
}

.sendForAgencyReportCheckbox {
    font-size: 0.875rem;
    margin-top: 1rem;
}

.actionsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 8rem;
}