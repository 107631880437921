.main {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.more {
    margin-left: 0.5rem;
    cursor: pointer;
}