.main {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem;
    width: 24rem
}

.logoContainer {
    display: flex;
    justify-content: center;
}

.logo {
    width: 5rem;
}

.heading {
    font-size: 1.25rem;
    font-weight: 400;
    text-align: center;
    padding: 1rem 0;
}

.container {
    width: 20rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.timerContainer {
    width: 16.5rem;
    display: flex;
    margin-top: 1rem;
    justify-content: center;
    font-size: 0.875rem;
    align-items: center;
}

.verifyBtn {
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.cancelBtn {
    width: 40%;
    margin-top: 3rem;
    margin-bottom: 1rem;
}

.otpInputContainer {
    display: flex;
    gap: 0.5rem;
    margin-top: 1.5rem;
    height: 2.5rem;
    align-items: center;
}

.input {
    width: 2rem;
    height: 2rem;
    text-align: center;
    border: 1px solid var(--border-light-grey);
    border-radius: 0.375rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 0.875rem;
}

.input:focus {
    border: 1px solid var(--app-primary);
    box-shadow: 0 0 0 1px var(--app-primary)
}

.description {
    font-size: 0.875rem;
    width: 100%;
    text-align: center;
}

.error {
    border: 1px solid var(--app-warning);
}