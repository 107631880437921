.main {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.inputContainer {
    display: flex;
    align-items: center;
    border: 1px solid var(--border-light-grey);
    border-radius: 0.375rem;
    flex: 1;
}

.inputContainer.password {
    position: relative;
}

.textArea,
.input {
    min-height: 1.75rem;
    border: none;
    border-radius: 0.375rem;
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    padding-left: 0.5rem;
    font-size: 0.875rem;
}

.textArea {
    resize: vertical;
}

.errorText {
    display: flex;
    font-size: 0.75rem;
    color: var(--app-warning);
    margin-top: 0.125rem;
}

.error {
    border: 1px solid var(--app-warning);
}

.eyeIcon {
    position: absolute;
    right: 0;
    padding: 0 0.5rem;
    cursor: pointer;
    font-size: 1rem;
    width: 2rem;
}
