.main {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
}

.allSelected {
    background-color: hsl(0, 0%, 90%);
    padding: 0.25rem 0.5rem;
}

.errorText {
    display: flex;
    font-size: 0.75rem;
    color: var(--app-warning);
    margin-top: 0.125rem;
}

.error>div {
    border: 1px solid var(--app-warning);
}

.select>div>div>div {
    max-width: 85%;
}

.select>div>div:first-child>div:last-child {
    width: 1rem;
}