.main {
    background-color: #fcfcfc;
    border: 1px solid #D7D7D7;
    width: 18rem;
    padding: 1.5rem 1rem;
    border-radius: 1.25rem;
}

.podName {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 0.125rem solid var(--app-success);
    border-radius: 1rem;
    padding: 0.25rem 1rem;
    min-width: 3rem;
    width: fit-content;
    max-width: 50%;
}

.podName span {
    font-size: 0.75rem;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: uppercase;
}

.eventName {
    font-weight: bold;
    padding: 0.5rem 0;
    margin-bottom: 0.5rem;
}

.medicalServices {
    font-size: 0.875rem;
    color: #8E8E8E;
    padding: 0.625rem 0 0.5rem;
}

.medicalServicesContainer {
    padding-bottom: 0.75rem;
    border-bottom: 1px solid #D7D7D7;
    margin-bottom: 1rem;
}

.viewEvent {
    display: flex;
    justify-content: center;
    color: var(--app-primary);
    font-weight: 500;
    cursor: pointer;
}

.fieldsContainer {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
}

.iconContainer {
    display: flex;
    gap: 0.5rem;
}

.iconFieldData {
    display: flex;
    align-items: center;
    color: #464646;
    font-size: 0.875rem;
}