.dragDropField {
    height: 5rem !important;
}

.uploadIcon {
    font-size: 2rem !important;
}

.actions {
    display: flex;
    padding-top: 1.5rem;
}

.actionBtn {
    width: 10rem;
}

.docName {
    font-weight: 500;
}