.container {
    border: 1px solid var(--border-light-grey);
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0.625rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
}

.slotText {
    color: green;
    font-size: 0.75rem;
    font-weight: 500;
}

.noSlotText {
    color: red;
}