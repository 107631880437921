.main {
    margin: 0.5rem 0;
    display: flex;
    gap: 0.5rem;
}

.name {
    color: var(--app-grey);
    font-size: 0.875rem;
}

.data {
    margin-left: 0.25rem;
    font-size: 0.875rem;
}