.filterContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.filters {
  flex: 1;
  gap: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.filterRight {
  display: flex;
  gap: 1rem;
  align-self: flex-end;
}

.eventDropDown {
  flex: 1;
  display: flex;
  max-width: 20rem;
}

.podSelect {
  max-width: 20rem;
}