.main {
    flex: 1;
}

.eventItem {
    background-color: var(--app-semi-light-grey);
    padding: 0.5rem;
    border: 1px solid #E1E1E1;
    border-radius: 0.75rem;
}

.basicInfo {
    margin-top: 0.25rem;
    display: flex;
    justify-content: space-between;
}

.eventName {
    font-size: 0.75rem;
    font-weight: 500;
    margin: 0;
    word-wrap: break-word;
    width: 6.875rem;
    margin-top: 0.125rem;
}

.timeAndLocationInfo {
    margin-top: 1rem;
    font-size: 0.75rem;
}

.time {
    display: flex;
    align-items: center;
}

.location {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1rem;
}

.medServices {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
    margin-top: 1rem;
}

.copyContainer {
    padding: 0.25rem;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
}

.copyIcon {
    cursor: pointer;
    width: 1rem;
    height: 1rem;
    object-fit: contain;
}

.copiedContainer {
    background-color: rgb(156, 242, 156);
}

@media screen and (max-width: 1450px) {
    .eventName {
        width: 6rem;
    }
}