.main {
    position: relative;
}

.iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.375rem;
    cursor: pointer;
}

.menuContainer {
    position: fixed;
    padding: 0.375rem 0;
    min-width: 6.25rem;
    background-color: var(--app-semi-light-grey);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.375rem;
    z-index: 2;
}

.menu {
    white-space: nowrap;
    padding: 0.5rem 1rem;
    cursor: pointer;
    height: 2.25rem;
    box-sizing: border-box;
}

.menu:hover {
    background-color: var(--app-white);
}