.filterContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
}

.filters {
    flex: 1;
    gap: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.icon {
    cursor: pointer;
    font-size: 1.2rem;
    align-self: center;
}

.actionBtn {
    align-self: last baseline;
}

.filterDates {
    max-width: 12rem;
}

.eventTypeDropDown {
    flex: 1;
    display: flex;
    max-width: 12rem;
}

.cardContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 1rem;
    max-height: 100%;
    overflow-y: auto;
}

.searchContainer {
    align-self: flex-end;
}

.filterBtn {
    align-self: flex-end;
}

.switchBtnContainer {
    display: flex;
    gap: 0.25rem;
}

.switchBtn {
    cursor: pointer;
}

.eventServiceContainer {
    display: flex;
    align-items: center;
}

.ordersWarning {
    font-size: 0.875rem;
}