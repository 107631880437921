.main {
    font-size: 1.125rem;
    color: var(--app-grey);
    margin: 0 1rem;
    cursor: pointer;
    padding-bottom: 0.75rem;
    white-space: nowrap;
}

.active {
    font-weight: bold;
    color: var(--app-black);
    border-bottom: 0.125rem solid var(--app-primary);
}