.actions {
    display: flex;
    gap: 0.75rem;
    width: 100%;
    justify-content: center;
    margin-top: 1.5rem;
}

.inputContainer {
    margin-top: 1rem;
}

.main {
    width: 25rem;
}