.detailsTop {
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
  padding: 0 0.875rem;
}

.name {
  font-size: 1.25rem;
  font-weight: bold;
}

.serviceContainer {
  border-right: 1px solid var(--border-light-grey);
}

.medServiceTitle {
  display: flex;
  align-items: center;
  color: var(--app-grey);
  font-size: 1rem;
  font-weight: bold;
}

.medServices {
  display: flex;
  gap: 0.875rem;
  margin-top: 1.875rem;
  flex-wrap: wrap;
}

.overview {
  width: 35%;
}

.cards {
  margin: 0.875rem 0;
  display: grid;
  grid-template-columns: auto auto;
  gap: 0.5rem;
  max-width: 60%;
}

.container {
  width: 30%;
}

.medicationContainer {
  width: 35%;
}

.noteTitle {
  font-size: 1.2rem;
  font-weight: bold;
}

.noteBox {
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  background-color: var(--app-white);
  margin-top: 2rem;
  box-sizing: border-box;
}

.noteTextarea {
  flex: 1;
  height: 6rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  border: none;
  resize: none;
}

.uploadBtn {
  width: 8rem;
  margin-top: 2rem;
}

.action {
  display: flex;
  justify-content: flex-end;
}

.overview,
.medicationContainer {
  padding-left: 1.5rem;
  border-left: 1px solid var(--border-light-grey);
}

.dataRow,
.timeContainer,
.date {
  color: var(--app-black);
  font-size: 1rem;
  font-weight: 400;
}

.date {
  margin-top: 0.75rem;
}

.timeContainer {
  width: 9rem;
}

.capacity {
  display: flex;
  gap: 2rem;
  margin-top: 0.875rem;
}

.card {
  margin-top: 3rem;
}

.userContainer {
  display: flex;
  gap: 2rem;
}

.dateAndTime {
  font-size: 0.875rem;
  color: var(--app-grey);
  font-style: italic;
}

.notesContainer {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-bottom: 1rem;
  max-height: 14rem;
  overflow-y: auto;
}

.userName {
  color: var(--app-grey);
}

.medProfsTable>div {
  max-height: 25rem;
  padding-right: 2rem;
}

.closeBox {
  margin-top: 3rem;
}

.noteText {
  margin-top: 0.125rem;
}

.eventName {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.status {
  padding: 0.375rem;
  font-weight: 500;
  min-width: 3rem;
}

.location {
  margin-top: 0.875rem;
}

.detailsTop>div {
  padding-right: 1rem;
}

.medicationCard {
  background-color: #eefcef;
  width: 100%;
  height: 13rem;
  overflow-y: auto;
  padding: 0.5rem 1rem;
  margin: 0.875rem 0;
}

.medications {
  border-bottom: 1px solid var(--border-light-grey);
  padding-bottom: 0.5rem;
  margin-top: 0.75rem;
}

.medicationName {
  font-size: 0.85rem;
}

.medicationCount {
  font-size: 0.875rem;
  font-weight: 700;
  margin-top: 0.25rem;
}

@media screen and (max-width: 1154px) {
  .detailsTop {
    gap: 0.125rem;
    padding: 0;
  }

  .capacity {
    gap: 0.25rem;
  }

  .detailsTop>div {
    padding-right: 0.25rem;
  }

  .overview,
  .medicationContainer {
    padding-left: 0.5rem;
    margin-right: 2.5rem;
  }
}