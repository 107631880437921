.main {
    padding: 1rem 0;
}

.serviceContainer {
    background-color: var(--app-light-purple);
    padding: 1rem;
    padding-left: 1.5rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    font-size: 0.875rem;
    color: var(--app-grey);
}

.actions {
    display: flex;
}

.editAction {
    margin-right: 1rem;
}