.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 15rem;
    height: 8.75rem;
    border-radius: 1rem;
}

.value {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 0.125rem;
}

.name {
    font-size: 0.875rem;
    font-weight: 500;
}