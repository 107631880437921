.label {
    font-size: 0.875rem;
    color: var(--app-grey);
    margin-bottom: 0.25rem;
    display: flex;
    align-items: center;
}

.asterisk {
    color: var(--app-primary);
}

.errorText {
    display: flex;
    font-size: 0.75rem;
    color: var(--app-warning);
    margin-left: 0.5rem;
}