@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.6.1/css/font-awesome.min.css");

select {
    font-family: 'FontAwesome', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.main {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.inputContainer {
    display: flex;
    align-items: center;
    flex: 1;
    border: 1px solid var(--border-light-grey);
    border-radius: 0.375rem;
}

.select {
    flex: 1;
    min-height: 1.75rem;
    border: none;
    outline: none;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    padding-left:
        0.5rem;
    background: url("../../assets/icons/arrow-down.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 0.5rem) center !important;
    -webkit-appearance: none;
}

.asterisk {
    color: var(--app-primary);
}

.errorText {
    display: flex;
    font-size: 0.75rem;
    color: var(--app-warning);
    margin-top: 0.125rem;
}

.error {
    border: 1px solid var(--app-warning);
}