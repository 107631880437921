.main {
    padding-right: 1rem;
}

.container {
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 0.5rem;
    width: 100%;
    margin-top: 1rem;
}

.gender {
    display: flex;
    flex-wrap: wrap;
    gap: 0.8rem;
    width: 100%;
}

.selectContainer {
    display: flex;
    gap: 1rem;
    width: 100%;
    margin-top: 0.5rem;
}

.country {
    height: 2.5rem;
    margin-top: 1.125rem;
}

.contactInformation>div {
    display: flex;
    justify-content: space-between;
}

.nameInput {
    margin: 1rem 0;
}

.addressContainer {
    margin-top: 1rem;
}

.actions {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 2rem;
}

.nameContainer {
    display: flex;
    gap: 0.5rem;
}

.nameContainer>div {
    flex: 1;
}

.nonEditableText {
    font-size: 0.75rem;
    font-weight: 500;
}

.zipContainer {
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 0.5rem;
    row-gap: 1rem;
    width: 100%;
    margin-top: 1rem;
}

.patientInfoHeading,
.contactInfoHeading {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.contactInfoHeading,
.medicalInsurance,
.dobContainer,
.genderContainer {
    margin-top: 1rem;
}

.insuranceActions {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    width: 100%;
}

.inputAddress {
    margin-top: 1rem;
}

.infoQuestions {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    margin-top: 0.5rem;
}