.sidebarContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: min-content;
    overflow-y: auto;
    background-color:  var(--app-white);
    border-radius: 1rem;
    padding: 0.75rem;
}

.logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: var(--app-primary);
    padding: 1rem 0 1.5rem;
}

.logo {
    width: 4.5rem;
}

.sidebars {
    flex: 1;
}