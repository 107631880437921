.main {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100%;
  overflow-y: auto;
}

.filterContainer {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.filters {
  gap: 1.5rem;
  display: flex;
  flex-wrap: wrap;
}

.searchBar {
  align-self: flex-end;
}

.filterBtn {
  align-self: flex-end;
}

.filterBtnContainer {
  display: flex;
  align-self: flex-end;
  gap: 1rem;
}

.podSelectContainer {
  max-width: 15.625rem;
  min-width: 10.625rem;
  height: 2rem;
}

.podSelect {
  width: 100%;
}

.eventServiceContainer {
  display: flex;
  align-items: center;
}

.eventDropDown {
  flex: 1;
  display: flex;
  max-width: 15.625rem;
  min-width: 15.625rem;
}

.attachments {
  text-wrap: nowrap;
}