.main {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    padding: 3rem;
}

.logoContainer {
    display: flex;
    justify-content: center;
}

.logo {
    width: 5rem;
}

.heading {
    font-size: 1.25rem;
    font-weight: 400;
    text-align: center;
    padding: 1rem 0;
}

.container {
    width: 24rem;
}

.inputContainer {
    margin-top: 1.5rem;
}

.input {
    height: 2rem;
}

.forgotPassword {
    margin-top: 0.875rem;
    font-size: 0.85rem;
    color: var(--app-primary);
    font-weight: 500;
}

.loginBtn {
    width: 100%;
    margin-top: 3rem;
    margin-bottom: 2rem;
}