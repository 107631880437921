.main {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.options {
    height: 5rem;
    overflow-y: auto;
    padding: 0.25rem;
}

.option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.125rem 0.25rem;
    font-size: 0.9rem;
}

.trashIcon {
    cursor: pointer;
    margin-left: 0.375rem;
}

.optionsContainer {
    display: flex;
    flex-direction: column;
}

.addOptionContainer {
    display: flex;
    justify-content: space-between;
}

.addOptionContainer>div {
    display: flex;
    align-items: last baseline;
    gap: 0.875rem;
}

.actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1rem;
}

.addOptionBtn {
    align-self: flex-end;
    margin-left: 0.75rem;
    margin-bottom: 0.25rem;
}

.reasonCheckBox {
    color: var(--app-grey);
}

.response,
.question {
    margin-top: 0.5rem;
}

.response {
    min-height: 4rem;
}

.logicQuestionSelect {
    width: 100%;
}

.questionTypeSelect {
    flex: 0.7;
}

.addOption {
    flex: 1;
    display: flex;
    align-items: last baseline;
    gap: 0.875rem;
}

.optionList {
    display: flex;
    align-items: center;
    gap: 3rem;
}

.checkbox {
    font-size: 0.875rem;
}