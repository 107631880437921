.main {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    padding: 3rem;
    min-height: 20rem;
}

.logoContainer {
    display: flex;
    justify-content: center;
}

.logo {
    width: 5rem;
}

.heading {
    font-size: 1.25rem;
    font-weight: 400;
    text-align: center;
    padding: 1rem 0;
}

.container {
    width: 24rem;
}

.inputContainer {
    margin-top: 1.5rem;
}

.input {
    height: 2rem;
}

.actions {
    display: flex;
    margin-top: 8rem;
    width: 100%;
    justify-content: center;
}

.sentText {
    max-width: 24rem;
}

.resetBtn {
    width: 100%;
}

.expiredText {
    margin-top: 1rem;
    width: 20rem;
}

.expiredText>a {
    color: var(--app-primary);
    margin-left: 0.25rem;
}

.resendLink {
    margin-top: 2rem;
    display: flex;
    gap: 0.25rem;
}

.backToLogin {
    margin-top: 1rem;
}