.main,
.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 22rem;
}

.actions {
    display: flex;
    margin-top: 1rem;
}

.actionsBtn {
    flex: 1;
}

.patientName {
    font-size: 1rem;
    font-weight: 600;
}

.age {
    font-size: 0.875rem;
    margin-top: 0.25rem;
    margin-bottom: 1.25rem;
}

.dataFieldText {
    margin: 0.5rem 0;
    font-size: 0.875rem;
}

.service {
    margin-top: 1rem;
    padding: 0.5rem 0;
    border-top: 1px solid var(--app-semi-light-grey);
    border-bottom: 1px solid var(--app-semi-light-grey);
}

.card {
    margin: 0.5rem 0;
}

.bloodPressure {
    display: flex;
    gap: 0.5rem;
}

.observations,
.symptoms,
.medications {
    font-size: 1rem;
    color: var(--app-grey);
}

.symptoms {
    margin-top: 1.875rem;
}

.symptomsDescription {
    font-size: 0.875rem;
    margin-top: 0.5rem;
}

.medicationsAdministered {
    margin-bottom: 1.875rem;
}

.administeredTime {
    margin-bottom: 0.5rem;
}