.main {
    padding-top: 1rem;
    flex: 1;
}

.name {
    font-size: 0.875rem;
    color: var(--app-grey);
    margin-bottom: 0.5rem;
}

.container {
    background-color: var(--app-light-purple);
    border: 2px dashed var(--app-primary);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0;
    border-radius: 0.375rem;
    cursor: pointer;
    height: 20rem;
}

.icon {
    font-size: 3rem;
    color: var(--app-primary);
    margin-top: 1rem;
}

.clickHere {
    margin-top: 0.75rem;
}