.main {
    padding: 0.625rem 1rem;
    background-color: var(--app-primary);
    color: var(--app-white);
    border-radius: 0.25rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: max-content;
    border: 1px solid var(--app-primary);
    min-width: 6rem;
    gap: 0.5rem;
}

.main:hover {
    background-color: var(--app-primary-dark);
}

.main.transparent {
    background-color: transparent;
    color: var(--app-primary);
    border: 1px solid var(--app-primary);
}

.main.transparent:hover {
    background-color: transparent;
    color: var(--app-primary-dark);
    border: 1px solid var(--app-primary-dark);
}

.icon {
    font-size: 1.125rem;
    display: flex;
    align-items: center;
}

.main>span {
    font-size: 0.9rem;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.main:focus {
    outline: 1px dotted black;
}