.leftContainer {
    flex: 5;
    display: flex;
}

.rightContainer {
    flex: 2;
    display: flex;
}

.innerContainer {
    margin-top: 1rem;
}