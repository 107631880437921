.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: rgba(0, 0, 0, 0.5);
    border-radius: inherit;
}

.modal {
    padding: 2.5rem;
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 100;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 5px;
    max-height: 90%;
    display: flex;
    flex-direction: column;
}

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.25rem;
    font-weight: 400;
}

.body {
    margin: 1.25rem 0px;
    flex: 1;
    overflow-y: auto;
}

.footer {
    display: flex;
    align-items: center;
}

.close {
    cursor: pointer;
}