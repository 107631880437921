.main {
    margin: 0.5rem 0;
}

.nameContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.name {
    font-weight: bold;
}

.icon {
    font-size: 1.5rem;
    color: var(--app-grey);
}