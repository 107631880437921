.main {
  padding: 1rem;
  width: 6.625rem;
  height: max-content;
  border-radius: 1rem;
  text-align: center;
}

.name {
  font-size: 0.875rem;
  margin-bottom: 1rem;
  font-weight: 500;
}

.value {
  font-size: 1.375rem;
  font-weight: 500;
}