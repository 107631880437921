.main {
    margin: 0.5rem 0;
}

.nameContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin: 0.125rem 0;
}

.name {
    font-weight: bold;
}